"use strict";

(function () {
  'use strict';

  var panelSettingsGeneralLanguage = {
    modalSuccess: {
      title: 'Parabéns, sua pesquisa foi criada com sucesso!',
      description: "\xC9 importante destacar que a pesquisa leva um tempo para ser processada e come\xE7ar a trazer as publica\xE7\xF5es no War-Room. Enquanto isso, voc\xEA pode continuar editando a pesquisa para otimizar seus dados e a <a href='https://help.blip.ai/hc/pt-br' target=\"_blank\"> Central de Ajuda </a> pode ser de grande ajuda nesse processo :)",
      cancelButton: 'Ir para o War-Room',
      confirmButton: 'Continuar editando'
    }
  };
  window.moduleExport({
    panelSettingsGeneralLanguage: panelSettingsGeneralLanguage
  }, typeof module !== 'undefined' && module);
})();